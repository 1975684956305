<template>
    <div class="content_container regular_loan">
		<div class="content">
			<div class="banner">
				<h1>기업금융</h1>
			</div>
			<ul class="common_tab">
				<li class="current">
					<router-link to="/product/EnterpriseNormal">일반 대출</router-link>
				</li>
				<li>
					<router-link to="/product/EnterpriseRealty">부동산 PF</router-link>
				</li>
			</ul>
			<h2 class="title_product">일반 대출</h2>
			<section class="overview">
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/regular_loan/regular_loan_overview_icon01.svg" alt="Working Capital" />
							<p>Working<br />Capital</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub//regular_loan/regular_loan_overview_icon02.svg" alt="Short-term Finance" />
							<p>Short-term<br />Finance</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub//regular_loan/regular_loan_overview_icon03.svg" alt="Long-term Finance" />
							<p>Long-term<br />Finance</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<p>기업의 신용도나 보유자산을 담보로 한 운전자금 대출업무로서 1년 미만의 단기 대출 뿐 아니라
						시설자금과 연계한 대출 및 1년 이상의 장기운전자금으로도 사용이 가능합니다.
					</p>
				</div>
			</section>
			<section class="structure">
				<h3>운용구조</h3>
				<div class="structure_infogp">
					<p><img src="@/assets/images/sub/regular_loan/rl_structure_infogp.svg" alt="무궁화캐피탈의 일반대출 운용구조는 기업은 무궁화캐피탈에게 신용도 및 담보를 제공, 무궁화캐피탈은 필요자금을 제공합니다"></p>
				</div>	
			</section>
			<section class="process">
				<h3>업무 프로세스</h3>
				<div class="float_wrapper">
					<ul class="float_content">
						<li>
							<div class="num">1</div>
							<img src="@/assets/images/sub/regular_loan/icon_process_01.svg" alt="대출상담" />
							<p class="title">대출상담</p>
							<p class="text">차주의 신용도, 자금용도, 담보내역 등을 전화 또는 방문상담으로 심사</p>
						</li>
						<li>
							<div class="num">2</div>
							<img src="@/assets/images/sub/regular_loan/icon_process_02.svg" alt="대출신청" />
							<p class="title">대출신청</p>
							<p class="text">사업계획서 등 관련 자료를 제출</p>
						</li>
						<li>
							<div class="num">3</div>
							<img src="@/assets/images/sub/regular_loan/icon_process_03.svg" alt="대출심사" />
							<p class="title">대출심사</p>
							<p class="text">자료검토, 기타 심사 등을 통해 대출이 적격한지를 심사</p>
						</li>
						<li>
							<div class="num">4</div>
							<img src="@/assets/images/sub/regular_loan/icon_process_04.svg" alt="계약체결 및 자금인출" />
							<p class="title">계약체결 및 자금인출</p>
							<p class="text">심사가 통과되면 계약을 체결하고 대출을 실행</p>
						</li>
					</ul>
				</div>
			</section>

			
			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

